import { template as template_44ead62ef75642c9ad98334cdcda2433 } from "@ember/template-compiler";
const FKLabel = template_44ead62ef75642c9ad98334cdcda2433(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
