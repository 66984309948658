import { template as template_269a96aa2f5d45dd920d2b7c8cdc5a1a } from "@ember/template-compiler";
const FKText = template_269a96aa2f5d45dd920d2b7c8cdc5a1a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
